{
    "header": "Heiliges ARAM-am-Abend Gesetzbuch (HAramAAGB)",
    "paragraphs": [
        {
            "header": "Anwendbarkeit",
            "sections": [
                "Dieses Gesetz findet abweichend nachfolgender Bestimmungen Anwendung im Spielmodus All-Random-All-Mid (ARAM).",
                "Die Karte des Spielmodus ist Howling Abyss.",
                "In Fällen eines ARAM-Clash's sind die nachfolgenden Regeln in der Hinsicht entsprechend anwendbar, sodass der Sieg die oberste Priorität ist.",
                "Ein ARAM ist wichtiger, als ein Ranked."
            ]
        },
        {
            "header": "Anzahl der ARAM",
            "sections": [
                "Generell sind drei ARAM zu spielen. Die zu verwendende Bezeichnung ist: ARAM-am-Abend (AAA).",
                "Liegen triftige Gründe vor, die die Durchführung des AAA nach Abs. 1 verhindern, kann die Anzahl der ARAM auf eins reduziert werden. Zwei ARAM sind grundsätzlich verboten.",
                "Die für Abs. 1 und 2 zu verwendende Anzahl der ARAM ist zum Modulus 3 zu berechnen.",
                "Gespielte ARAM im Sinne des Abs. 1 sind nur solche, die nicht geremaked wurden.",
                "Dieser Vorschrift steht ein Tageswechsel nicht entgegen.",
                "Wird eine unzulässige Anzahl an ARAM gespielt ist § 9 anzuwenden. Die Ausnahmen nach § 3 sind zu beachten."
            ]
        },
        {
            "header": "Ausnahmen",
            "sections": [
                "In verzweifelten Fällen werden in Übereinkunft beteiligter Personen ARAM gespielt und durch eine gemeinsame Bekundung durch ein „ARGHHHHHHHHH“, welches klar und deutlich zu erkennen sein muss, kenntlich gemacht, bis ein Sieg errungen werden kann. § 2 Abs. 3 ist nicht anzuwenden.",
                "Im Falle des § 2 Abs. 2 Satz 2 dieser Vorschrift ist, die Bereitschaft nur zwei ARAM spielen zu wollen offenkundig zu machen. Diese Vorschrift ist nur in Ausnahmefällen anzuwenden. Dies ist insbesondere dann gegeben, wenn den Teilnehmern wenig Zeit aufgrund morgendlicher Verpflichtungen zur Verfügung steht. Sanktionen sind nicht zu verhängen. § 2 Abs. 3 ist entsprechend anzuwenden."
            ]
        },
        {
            "header": "Summoner Spells",
            "sections": [
                "Die AAA-Summoner-Spells sind Flash und Snowball.",
                "Von dieser Regel darf nur durch ein Mehrheitsvotum oder durch Pick des Champions „Yuumi“ abgewichen werden. Satz 2 ist für ähnliche Champions entsprechend anzuwenden.",
                "Nichtbefolgung zieht das Schreiben von “report [Spieler] for lack of snowball” durch verbleibende Gruppenmitglieder im für das gegnerische Team sichtbaren Kommunikationskanal nach sich. § 9 ist anzuwenden",
                "In Ausnahmefällen kann anstelle von Flash ein vergleichbarer Summoner-Spell gewählt werden. Dies gilt nur in Fällen, bei denen die Effizienz des Picks durch den anderen Summoner-Spell erhöht werden kann."
            ]
        },
        {
            "header": "Finish",
            "sections": [
                "Möglichkeiten zum siegreichen Beenden des ARAM dürfen nur in Anspruch genommen werden, wenn ansonsten die Gefahr der eigenen Niederlage nach übereinstimmender Einschätzung der beteiligten Personen unabwendbar erscheint.",
                "Genannte Bedingung muss nicht erfüllt sein, wenn die eigenen Nexustürme bereits zerstört wurden. (Nomos-Regel).",
                "Aus Zeitmangel darf von Abs. (1) abgewichen werden.",
                "§ 5 findet keine Anwendung in Fällen des § 1 Abs. 3."
            ]
        },
        {
            "header": "ARAM-Gott",
            "sections": [
                "Den Weisungen einer Person mit mindestens 2000 ARAM-Spielen (ARAM-Gott) ist stets Folge zu leisten.",
                "Der ARAM-Gott ist befugt eine Reduzierung oder Erhöhung der ARAM gemäß § 3 anzuordnen.",
                "Bei Uneinigkeiten unter ARAM-Göttern, hat der ARAM-Gott recht, der mehr ARAM gespielt hat.",
                "Bei Gleichstand des Abs. 3 entscheidet der Spieler mit der höheren ARAM-Winrate. Ist durch Rundung auf die zweite Nachkommastelle der Gleichstand nicht entschieden, so entscheidet ein Münzwurf."
            ]
        },
        {
            "header": "Kerngedanke",
            "sections": [
                "Der Kerngedanke des AAA lautet: „Einfach Rein!“.",
                "Getroffene Schneebälle müssen grundsätzlich reaktiviert werden, wenn die Möglichkeit des gegnerischen Todes besteht (captain-Gesetz).",
                "In Fällen des § 1 Abs. 3 ist eine Abwägung des Abs. 2 zwingend durchzuführen. Von dieser Regelung darf abgewichen werden, wenn Bedingungen gemäß § 4 Abs. 2 zutreffen."
            ]
        },
        {
            "header": "Weitere Bestimmungen",
            "sections": [
                "Ein Reroll ist zu benutzen, wenn zwei Rerolls vorhanden sind.",
                "Dem ARAM darf weder im Vorhinein gedodged, noch darf es während des Spiels surrendert werden. Dies gilt nicht, wenn triftige Gründe angeführt werden können.",
                "Ein Tod, der nach bestem Wissen und Gewissen als Execute gewertet werden wird, ist zulässig. Dies gilt nicht, wenn die begründete Annahme besteht, dass der Tod unmittelbar zur Niederlage des ARAM führen wird, insbesondere in Fällen des § 1 Abs. 3. Der ARAM-Gott ist befugt vorrübergehend die Zulässigkeit der Executes zu unterbinden.",
                "Die Regeln dieses Gesetzes finden auf ähnliche Spielmodi entsprechende Anwendung.",
                "Flash kann genutzt werden, um einen Heal zu erlangen. Dies gilt nicht in Fällen des § 1 Abs. 3.",
                "Eine Liste, welche die längsten ARAM auflistet, wird erstellt. Dabei sind Uhrzeit des Beginns und des Endes entscheidend. Die Zeit wird mittels des Clients berechnet.",
                "Regelmäßige Teilnehmer des AAA sollen in ihrem League-Status die Anzahl ihrer ARAM angeben. Dies gilt nicht bei besonderen Anlässen."
            ]
        },
        {
            "header": "Sanktionen",
            "sections": [
                "Nichtbefolgung einer Regel wird mit zehn Liegestützen geahndet.",
                "Über die Durchführung der Strafe wacht der in § 10 Abs. 2 genannte Personenkreis.",
                "In Ausnahefällen kann die Strafe durch Mehrheitsbeschluss erlassen werden."
            ]
        },
        {
            "header": "Inkrafttreten, Bindung",
            "sections": [
                "Dieses Gesetz tritt durch seine Verkündung im Teamspeak in Kraft. Es ist von CodeHat auf entsprechender Webseite zugänglich zu machen.",
                "Sämtliche nachfolgenden Personen haben das Gesetz ratifiziert (Jxxxxx, CxxxHxx, cxxxxxx0000, Ixxxxxxxx, Nxxxx00, Jxxxxx, Kxxxxx Bxxx).",
                "Die in Absatz 2 genannten Personen haben diesem Gesetz stets Folge zu leisten.",
                "Treten zu den in Abs. 2 genannten Personen eine weitere Person in einem Spiel hinzu, so wird diese Person auf dieses Gesetz hingewiesen."
            ]
        }
    ],
    "footer": "Gezeichnet\nDer Elohell-Präsident\nCaptain Teemo",
    "version": "20/07/2023"
}
